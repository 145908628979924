/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';

// import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import GridItem from '../components/GridItem';

import { breakpoint } from '../theme';
import { getTerm } from '../utils/helperFunctions';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(50)} auto;
`;

// const PageTitle = styled.h1`
//   line-height: 1.2em;
//   font-size: ${rem(20)};

//   ${breakpoint('large')} {
//     font-size: ${rem(26)};
//   }
// `;

const Item = styled(Col)`
  text-align: center;
  width: 48%;
  margin-bottom: ${(props) => props.theme.spacing.gutter};

  ${breakpoint('medium')} {
    width: 33.333%;
  }

  ${breakpoint('mediumlarge')} {
    width: 25%;
  }

  /* ${breakpoint('large')} {
    width: 17%;
  }

  ${breakpoint('xlarge')} {
    width: 14%;
  } */
`;

function Leagues({ data }) {
  const { allLeaguesJson: leagues, allSportsJson: sports } = data;

  // useLangRedirect(team);

  if (!leagues) {
    return null;
  }

  return (
    <Layout>
      <SEO title="Ligor med publikfigurer" />

      <Hero
        heading="Välj liga"
        // image={featuredMedia?.localFile}
        pageTitle
        // preHeading="Pre heading"
        // actions="Actions"
        tint
      />

      <Container>
        <Row style={{ justifyContent: 'center' }}>
          {leagues.edges.map(({ node: league }) => (
            <Item key={league.id}>
              <GridItem
                link={`/${league.sport}/${league.slug}/`}
                title={league.title}
                subTitle={getTerm(league.sport, sports)?.title}
                image={league.logo}
              />
            </Item>
          ))}
        </Row>
      </Container>
    </Layout>
  );
}

Leagues.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query {
    allLeaguesJson {
      edges {
        node {
          id
          title
          slug
          sport
          logo {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    allSportsJson {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`;

export default Leagues;
